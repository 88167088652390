import { Main } from "./Main";

function App() {
	return (
		<div>
			<Main />
		</div>
	);
}

export default App;
