import React from "react";
import "./Main.css";
import MainLogo from "./assets/VinkyBox.svg";
import ComingSoon from "./assets/ComingSoon2.svg";

export const Main = () => {
	return (
		<div className="main">
			<img src={MainLogo} />
			<img src={ComingSoon} />
		</div>
	);
};
